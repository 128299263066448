
import '@/pdfjs-dist/es5/build/pdf'
import * as pdfApp from '@/pdfjs-dist/lib/web/app'
import { AppOptions } from '@/pdfjs-dist/lib/web/app_options'
import '@/pdfjs-dist/lib/web/genericcom'
import '@/pdfjs-dist/lib/web/pdf_print_service'
import '@/pdfjs-dist/build/pdf.worker.entry'
import '@/sass/index.scss'
import { ToolbarConfig, Theme, ToolbarIdConfig, PageScale } from '@/types'
import getAppConfig from '@/utils/pdf-config'
import { PDF_FILE_INPUT_ID } from '@/utils/constants'
import locale from '@/utils/locale'
import { getToolbarConfigValue, toolbarConfig } from '@/utils/toolbar-config'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch
} from 'vue'

if (AppOptions) {
  AppOptions.set('defaultUrl', null)
}

const themeCacheKey = 'vue-pdf-app-theme'
const errorHandler = console.error.bind(console)

// pdf_print_service reassigns window.print.
// Assign original window.print on component destroy.
// Once pdf is opened again assign window.print = pdfjs.print
const pdfPrint = window.print.bind(window)
window.print = (window as any).__nativePrint__ || pdfPrint

export default defineComponent({
  props: {
    config: {
      default: () => toolbarConfig,
      type: Object as PropType<ToolbarConfig>
    },
    title: { type: Boolean, default: () => false },
    pdf: { type: [String, ArrayBuffer] },
    theme: String as PropType<Theme>,
    fileName: String,
    idConfig: { type: Object as PropType<ToolbarIdConfig> },
    pageScale: [Number, String] as PropType<PageScale>,
    pageNumber: Number
  },
  setup (props, ctx) {
    const defaultLocale = ref(JSON.stringify(locale))
    const isOpenHandlerBinded = ref(false)
    const isSidebarHidden = ref(true)
    const isFindbarHidden = ref(true)
    const cacheTheme = ref(
      window.localStorage.getItem(themeCacheKey) as Theme | null
    )

    const isSidebarToolbarHidden = computed(() => {
      const idConfig = props.idConfig as ToolbarIdConfig
      const isCustomToolbar =
        idConfig?.viewAttachments &&
        idConfig?.viewOutline &&
        idConfig?.viewThumbnail
      return isCustomToolbar || !props.config.sidebar
    })
    const isToolbarHidden = computed(() => {
      if (props.config.toolbar === false) return 'zero-top'
      return ''
    })
    const localTheme = computed<Theme>(() => {
      if (props.theme) return props.theme
      if (cacheTheme.value) return cacheTheme.value
      const prefersTheme = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('content')
        .replace(/"/g, '') as Theme
      if (['light', 'dark'].includes(prefersTheme)) return prefersTheme
      return 'dark'
    })
    const slotProps = computed(() => {
      return {
        toggleTheme: toggleTheme,
        isSidebarHidden: isSidebarHidden.value,
        isFindbarHidden: isFindbarHidden.value
      }
    })

    const toggleButtonUnmount = ref<Function>()
    const findbarButtonUnmount = ref<Function>()
    const fileInputUnmount = ref<Function>()
    const printContainerUnmount = ref<Function>()
    onBeforeUnmount(() => {
      destroyPdf()
      if (toggleButtonUnmount.value) {
        toggleButtonUnmount.value()
      }
      if (findbarButtonUnmount.value) {
        findbarButtonUnmount.value()
      }
      if (fileInputUnmount.value) {
        fileInputUnmount.value()
      }
      if (printContainerUnmount.value) {
        printContainerUnmount.value()
      }
    })

    window.print = pdfPrint
    pdfApp.PDFViewerApplication.isViewerEmbedded = !props.title
    ctx.emit('after-created', pdfApp.PDFViewerApplication)

    onMounted(() => {
      addPrintContainer()
      const config = getAppConfig(props.idConfig)
      if (pdfApp.PDFViewerApplication) {
        pdfApp.PDFViewerApplication.run(config)
        pdfApp.PDFViewerApplication.initializedPromise
          .then(setDefaultPageScale)
          .then(open)
          .then(bindSidebarToggleEvents)
          .then(bindFindbarToggleEvents)
          .catch(errorHandler)
      }
    })

    function bindSidebarToggleEvents () {
      const config = getAppConfig(props.idConfig)
      const toggleButton = config.sidebar.toggleButton
      const handler = checkSidebarVisibility
      toggleButton?.addEventListener('click', handler)
      toggleButtonUnmount.value = () => {
        toggleButton?.removeEventListener('click', handler)
      }
    }
    function bindFindbarToggleEvents () {
      const config = getAppConfig(props.idConfig)
      const toggleButton = config.findBar.toggleButton
      const handler = checkFindbarVisibility
      toggleButton?.addEventListener('click', handler)
      findbarButtonUnmount.value = () => {
        toggleButton?.removeEventListener('click', handler)
      }
    }
    function bindOpenHandler () {
      if (isOpenHandlerBinded.value) return
      const fileInput = document.getElementById(PDF_FILE_INPUT_ID)
      const fileInputHandler = async () => {
        await pdfApp.PDFViewerApplication.pdfLoadingTask?.promise
        openDocument()
      }
      fileInput?.addEventListener('change', fileInputHandler)
      fileInputUnmount.value = () => {
        fileInput?.removeEventListener('change', fileInputHandler)
      }
      isOpenHandlerBinded.value = true
    }
    function open () {
      clearCacheTimeout()
      if (!pdfApp.PDFViewerApplication) return
      if (!props.pdf) {
        pdfApp.PDFViewerApplication.close()
      } else {
        pdfApp.PDFViewerApplication.open(props.pdf)
          .then(() => {
            if (props.pageNumber) {
              setTimeout(
                () => (pdfApp.PDFViewerApplication.page = props.pageNumber)
              )
            }
            return pdfApp.PDFViewerApplication.pdfDocument?.getMetadata()
          })
          .then(
            (fileMetadata: { contentDispositionFilename: null | string }) => {
              pdfApp.PDFViewerApplication.contentDispositionFilename =
                props.fileName || fileMetadata.contentDispositionFilename
              ctx.emit('pages-rendered', pdfApp.PDFViewerApplication)
            }
          )
          .catch(errorHandler)
      }
    }

    function checkSidebarVisibility () {
      const sidebar = pdfApp.PDFViewerApplication?.pdfSidebar
      isSidebarHidden.value = !(sidebar && sidebar.isOpen)
    }
    function checkFindbarVisibility () {
      const findbar = pdfApp.PDFViewerApplication?.findBar
      isFindbarHidden.value = !(findbar && findbar.opened)
    }
    async function openDocument () {
      resetLoadingBar()
      ctx.emit('open', pdfApp.PDFViewerApplication)
      if (pdfApp.PDFViewerApplication?.pdfViewer?.pagesPromise) {
        await pdfApp.PDFViewerApplication.pdfViewer.pagesPromise.catch(
          errorHandler
        )
        if (props.pageNumber) {
          setTimeout(
            () => (pdfApp.PDFViewerApplication.page = props.pageNumber)
          )
        }
        checkSidebarVisibility()
        checkFindbarVisibility()
        ctx.emit('pages-rendered', pdfApp.PDFViewerApplication)
      }
    }

    function addPrintContainer () {
      const printElId = 'printContainer'
      const el = document.createElement('div')
      el.id = printElId
      document.body.appendChild(el)
      const styleEl = document.createElement('style')
      styleEl.type = 'text/css'
      styleEl.innerHTML = `
        @media print {
          body > *:not(#printContainer) {
            display: none !important;
        }
      }`
      document.head.appendChild(styleEl)
      printContainerUnmount.value = () => {
        document.body.removeChild(el)
        document.head.removeChild(styleEl)
      }
    }
    function destroyPdf (): void {
      clearCacheTimeout()
      pdfApp.PDFViewerApplication.unbindEvents()
      pdfApp.PDFViewerApplication.unbindWindowEvents()
      pdfApp.PDFViewerApplication.pdfDocument?.destroy()
      const el = document.getElementById(PDF_FILE_INPUT_ID)
      el && el.remove()
      // __nativePrint__ is assigned in pdf_print_service.js
      window.print = (window as any).__nativePrint__ || window.print
    }

    function toggleTheme () {
      const newTheme = localTheme.value === 'dark' ? 'light' : 'dark'
      ctx.emit('update:theme', newTheme)
      cacheTheme.value = newTheme
      window.localStorage.setItem(themeCacheKey, newTheme)
    }

    function clearCacheTimeout () {
      const cacheTimeoutId =
        pdfApp.PDFViewerApplication.pdfRenderingQueue?.idleTimeout
      clearTimeout(cacheTimeoutId)
    }
    function getScale (value: number): string {
      return `{ "scale": ${value} }`
    }

    function showElem (
      defaultToolbarPath: string,
      customToolbarElem?: keyof ToolbarIdConfig
    ): boolean {
      if (customToolbarElem && props.idConfig) {
        return !props.idConfig[customToolbarElem]
      }
      return !(
        getToolbarConfigValue(props.config, defaultToolbarPath) === false
      )
    }

    function setDefaultPageScale () {
      props.pageScale && AppOptions.set('defaultZoomValue', props.pageScale)
    }

    function resetLoadingBar () {
      pdfApp.PDFViewerApplication.loadingBar.show()
      pdfApp.PDFViewerApplication.loadingBar.percent = 0
    }

    watch(() => props.pdf, open)

    return {
      showElem,
      getScale,
      slotProps,
      isToolbarHidden,
      isSidebarToolbarHidden,
      localTheme,
      defaultLocale,
      bindOpenHandler
    }
  }
})
